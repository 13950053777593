import { quickSearchByQuery } from 'src/search/adapter/api-search-query-varus/search'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import config from 'config'
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers';

const headers = {
  'Accept': 'application/json, text/plain, */*',
  'Content-Type': 'application/json'
};

export const CheckoutService = {
  getCitiesList: async ({ query, excludeFields = null, includeFields = null, size = null, storeCode = null }): Promise<any> => {
    const toRequest = {
      query,
      storeCode,
      includeFields,
      excludeFields,
      entityType: 'tms_city',
      excludeStoreCode: true
    }

    if (size) toRequest['size'] = size

    return quickSearchByQuery(toRequest);
  },
  getShopsList: async (body): Promise<any> => {
    return quickSearchByQuery({
      ...body,
      size: body?.size || config.entities.tms_shop.queryMaxSize,
      entityType: 'tms_shop'
    });
  },
  availableBonuses: async (cartId: string): Promise<any> => {
    const endpoint = getApiEndpointUrl(config.cart, 'available_bonuses_endpoint')

    return TaskQueue.execute({
      url: processLocalizedURLAddress(endpoint),
      payload: {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify({
          cart_id: cartId
        })
      }
    });
  },
  getGeoLocateShop: async (lat: string, long: string, cityId: number | null = null): Promise<any> => {
    let url = [
      getApiEndpointUrl(config.user_checkout.endpoint, 'shopByLocation'),
      encodeURIComponent(lat),
      encodeURIComponent(long)
    ].join('/')

    if (cityId) {
      url = `${url}?city_id=${cityId}`
    }

    return TaskQueue.execute({
      url: url,
      payload: {
        method: 'GET',
        mode: 'cors',
        headers
      }
    });
  },
  getGeoLocateShopV2: async (lat: string, long: string, cityId: number | null = null): Promise<any> => {
    let url = [
      getApiEndpointUrl(config.user_checkout.endpoint, 'shopByLocationV2'),
      encodeURIComponent(lat),
      encodeURIComponent(long)
    ].join('/')

    if (cityId) {
      url = `${url}?city_id=${cityId}`
    }

    return TaskQueue.execute({
      url: url,
      payload: {
        method: 'GET',
        mode: 'cors',
        headers
      }
    });
  },
  deliveryTypesByLocation: async (lat: number, long: number, cityId: number | null = null): Promise<any> => {
    let url = [
      getApiEndpointUrl(config.user_checkout.endpoint, 'deliveryTypesByLocation'),
      encodeURIComponent(lat),
      encodeURIComponent(long)
    ].join('/')

    if (cityId) {
      url = `${url}?city_id=${cityId}`
    }

    return TaskQueue.execute({
      url: url,
      payload: {
        method: 'GET',
        mode: 'cors',
        headers
      }
    });
  },
  getShippingTimeSlots: async (payload): Promise<any> =>
    TaskQueue.execute({
      url: processLocalizedURLAddress(getApiEndpointUrl(config.user_checkout.endpoint, 'shippingTimeSlots')),
      payload: {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload),
        headers,
        silent: true
      }
    }),
  getShippingTimeSlotsV2: async (payload): Promise<any> =>
    TaskQueue.execute({
      url: processLocalizedURLAddress(getApiEndpointUrl(config.user_checkout.endpoint, 'shippingTimeSlotsV2')),
      payload: {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload),
        headers,
        silent: true
      }
    }),

  saveShippingDetails: async (payload): Promise<any> => {
    return TaskQueue.execute({
      url: processLocalizedURLAddress(getApiEndpointUrl(config.user_checkout.endpoint, 'setShippingDetails')),
      payload: {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(payload),
        headers
      },
      silent: true
    })
  },

  postPaymentStatusToTheServer: async (payload): Promise<any> =>
    TaskQueue.execute({ url: getApiEndpointUrl(config.ordersExt, 'payment_status_endpoint'),
      payload: {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify(payload)
      }
    })
};
