import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import CheckoutState from '../../types/CheckoutState'

const mutations: MutationTree<CheckoutState> = {
  /**
   * Setup current order object
   * @param state
   * @param {Object} order Object
   */
  [types.CHECKOUT_PLACE_ORDER] (state, order) {
    state.order = order
  },
  [types.CHECKOUT_SET_MODIFIED_AT] (state, timestamp) {
    state.modifiedAt = timestamp
  },
  [types.CHECKOUT_SAVE_PERSONAL_DETAILS] (state, personalDetails) {
    state.personalDetails = personalDetails
  },
  [types.CHECKOUT_SAVE_SHIPPING_DETAILS] (state, shippingDetails) {
    state.shippingDetails = shippingDetails
  },
  [types.CHECKOUT_SAVE_PAYMENT_DETAILS] (state, paymentDetails) {
    state.paymentDetails = paymentDetails
  },
  [types.CHECKOUT_SAVE_PACKAGING_DETAILS] (state, packagingDetails) {
    state.packagingDetails = packagingDetails
  },
  [types.CHECKOUT_LOAD_PERSONAL_DETAILS] (state, storedPersonalDetails) {
    state.personalDetails = storedPersonalDetails
  },
  [types.CHECKOUT_LOAD_PAYMENT_DETAILS] (state, storedPaymentDetails) {
    state.paymentDetails = storedPaymentDetails
  },
  [types.CHECKOUT_DROP_PASSWORD] (state) {
    state.personalDetails.password = ''
    state.personalDetails.createAccount = false
  },
  [types.CHECKOUT_SET_THANKYOU] (state, payload) {
    state.isThankYouPage = payload
  },
  [types.CHECKOUT_ADD_PAYMENT_METHOD] (state, paymentMethod) {
    state.paymentMethods.push(paymentMethod)
  },
  [types.CHECKOUT_SET_PAYMENT_METHODS] (state, paymentMethods = []) {
    state.paymentMethods = paymentMethods
  },
  [types.CHECKOUT_UPDATE_PAYMENT_DETAILS] (state, updateData = {}) {
    state.paymentDetails = Object.assign({}, state.paymentDetails, updateData)
  },
  [types.CHECKOUT_SET_ACTIVE_STEP] (state, step = '') {
    state.activeStep = step
  },
  [types.CHECKOUT_SET_ORDER_CONFIRMATION_INFO] (state, confirmation) {
    state.confirmation = confirmation
  },
  [types.CHECKOUT_SET_SUMMARY] (state, summary) {
    state.summary = summary
  },
  [types.SHIPPING_TIME_SLOTS] (state, slots) {
    state.shippingTimeSlots = slots
  },
  [types.SHIPPING_PAY_WITH_BONUSES_AVAILABLE] (state, value) {
    state.payWithBonuses.available = value
  },
  [types.SHIPPING_PAY_WITH_BONUSES_ACTIVE] (state, isActive) {
    state.payWithBonuses.active = isActive
    state.payWithBonuses.bonusesChanged = false
  },
  [types.SHIPPING_PAY_WITH_BONUSES_INITIALIZED] (state, isInitialized) {
    state.payWithBonuses.initialized = isInitialized
  },
  [types.SHIPPING_PAY_WITH_BONUSES_CHANGED] (state, isChanged) {
    state.payWithBonuses.bonusesChanged = isChanged
  },
  [types.SET_CURRENT_TIME_SLOT] (state, slot) {
    state.currentTimeSlot = slot
  }
}

export default mutations
