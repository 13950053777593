import { categoryExtStore } from './store/';
import { StorefrontModule } from '@vue-storefront/core/lib/modules';

export const CategoryExtensionModule: StorefrontModule = async function ({ store }, fromCache) {
  if (fromCache?.['category-extension']) {
    categoryExtStore.state = fromCache['category-extension']
  }

  store.registerModule('category-extension', categoryExtStore)
}
