import {
  getCurrentRegion,
  getCurrentShipping
} from 'theme/store/checkout/helpers';

export const promotionFilterQuery = (shopId = null) => {
  const shipping = getCurrentShipping()

  if (shipping?.shop) {
    return {
      key: 'has_promotion_in_stores',
      value: { 'in': [0, shopId] },
      scope: 'catalog'
    }
  }

  const region = getCurrentRegion();

  if (region?.id) {
    return {
      key: 'has_promotion_in_region',
      value: { 'in': [region.id] },
      scope: 'catalog'
    }
  }

  return {
    key: 'has_promotion_in_region',
    value: { 'eq': 0 },
    scope: 'catalog'
  }
}

export default function getPromotionFilter (route, shopId) {
  return route.params.hasOwnProperty('has_promotion_in_stores') && promotionFilterQuery(shopId)
}
