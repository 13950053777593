import store from '@vue-storefront/core/store'
import { getProductPrice, getProductStock } from 'theme/helpers';
import { newPostMethods, pickupMethods } from '$modules/shipping/config';
import { isServer } from '@vue-storefront/core/helpers';

export const getDefaultShopId = (): number => {
  const region = getCurrentRegion()

  if (region?.shipping_shop_id) {
    return region?.shipping_shop_id
  }

  return parseInt(store.getters['shipping-module/getDefaultShop']?.id || 0)
}

export const getCurrentRegion = (): { [key: string]: any } => {
  return (store.getters['shipping-module/getCurrentRegion'] || null)
}

export const getRegionList = (): { [key: string]: any } => {
  return (store.getters['shipping-module/getRegionList'] || null)
}

export const setCurrentRegion = (region: any) => {
  return store.dispatch('shipping-module/setCurrentRegion', region)
}

export const shopDeliveryPolygon = (): { [key: string]: any } => {
  return store.state['shipping-module'].shopDeliveryPolygon
}

export const getCurrentShopId = async (): Promise<number> => {
  let shopId = null

  if (!getDefaultShopId()) {
    const result = await store.dispatch('shipping-module/loadDefaultCityAndShipping', {})

    shopId = result.defaultShop?.id
  }

  return parseInt(store.getters['shipping/getShippingDetails']?.shopId || shopId || getDefaultShopId())
}

export const isCurrentNewPost = (): boolean => {
  return newPostMethods.includes(store.getters['shipping/getShippingDetails']?.shippingMethod)
}

export const isCurrentPickup = (): boolean => {
  return pickupMethods.includes(store.getters['shipping/getShippingDetails']?.shippingMethod)
}

export const isCurrentDarkstore = (): boolean => {
  return !!(+(store.state['shipping-module']?.current?.shop?.is_darkstore) || false)
}

const buildSQPPByShop = async (includeFields, extendShopId = null, shopDelivery = null) => {
  const shopId = extendShopId || await getCurrentShopId()

  const sqppShopKey = `sqpp_data_${shopId}`
  const deliveryKey = shopDelivery ? `sqpp_data_${shopDelivery}` : null
  const includeFieldsArray = includeFields || []

  const included = includeFieldsArray.length ? [
    ...includeFieldsArray,
    sqppShopKey
  ] : []

  if (deliveryKey) {
    included.push(deliveryKey)
  }

  return {
    included,
    key: sqppShopKey,
    deliveryKey,
    isRegion: false
  }
}

const buildSQPPByRegion = async (includeFields, region) => {
  const sqppShopKey = region.id ? `sqpp_data_region_${region.id}` : `sqpp_data_region_default`
  const includeFieldsArray = includeFields || []

  const included = includeFieldsArray.length ? [
    ...includeFieldsArray,
    sqppShopKey
  ] : []

  return {
    included,
    key: sqppShopKey,
    deliveryKey: null,
    isRegion: true
  }
}

export const isSkipSSR = (): boolean => {
  return !!(isServer && store.state.__SSR_CACHE__)
}

export const getCurrentShipping = (): { [key: string]: any } => {
  return (store.getters['shipping-module/getCurrent'] || null)
}

export const getSQPPShopKey = async (includeFields, extendShopId = null, isNewPost = false) => {
  const currentShipping = getCurrentShipping()
  const region = getCurrentRegion()

  if ((!currentShipping?.npShop && !currentShipping?.shop) && region) {
    return buildSQPPByRegion(includeFields, region)
  }

  const deliveryPolygon = isNewPost ? shopDeliveryPolygon() : null

  return buildSQPPByShop(includeFields, extendShopId, deliveryPolygon)
}

export const getMinSalableValue = (item) => (
  item?.['productminsalablequantity'] ||
    item?.['productquantityunitstep'] ||
    0
)

export const productRemap = (key: string, isRegion: boolean, deliveryKey: string | boolean = false) => (item) => {
  if (item.sqpp) return item

  const minSalableQty = getMinSalableValue(item)

  const sqpp = item[key]

  if (isRegion) {
    const stockQty = sqpp?.in_stock ? 1 : 0

    item.sqpp = {
      ...sqpp,
      qty: stockQty,
      qty_original: stockQty,
      sort_price: sqpp?.price,
      in_stock: sqpp?.in_stock
    }
  } else {
    const in_stock = sqpp?.qty >= minSalableQty

    item.sqpp = { ...sqpp, in_stock: in_stock }
  }

  if (typeof deliveryKey === 'string' && item?.[deliveryKey]) {
    const availability = item?.[deliveryKey]?.availability || {}

    item.sqpp.availability = {
      ...(item.sqpp.availability || {}),
      ...availability
    }
  }

  item.stock = getProductStock(item)
  item.price = getProductPrice(item)

  delete item[key]

  return item
}
