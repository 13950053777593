import { GetterTree } from 'vuex';
import RootState from '@vue-storefront/core/types/RootState';
import CategoryExtensionState from '../types/CategoryExtensionState';
import FilterVariant from '../types/FilterVariant';
import { optionLabel } from '@vue-storefront/core/modules/catalog/helpers';
import { compareByLabel } from '@vue-storefront/core/modules/catalog-next/helpers/categoryHelpers';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { products, attributes } from 'config'
import trim from 'lodash/trim'
import toString from 'lodash/toString'
import get from 'lodash/get'
import { getFiltersFromQuery } from 'theme/helpers/filterHelpers';

const getters: GetterTree<CategoryExtensionState, RootState> = {
  getChildCategories: (state) => state.childCategories,
  getRelatedChildCategories: (state) => state.relatedChildCategories,
  getCategories: state => state.categories,
  getVarusCafeCategories: state => state.varusCafeCategories,
  getVarusCafeCategoriesLoading: state => state.varusCafeCategoriesLoading,
  getCategoriesProductsCount: (state) => state.categoriesProductsCount,
  getIsCategoryProductsOutOfStock: state => state.isCategoryProductsOutOfStock,
  getFiltersMap: state => state.filtersMap,
  getAvailableFilters: (state, getters) => {
    const categoryId = get(getters.getCurrentCategory || {}, 'id', null)
    return getters.getFiltersMap[categoryId] || {}
  },
  getCurrentCategory: (state, getters, rootState, rootGetters) => {
    if (rootGetters['url/getCurrentRoute'].name === 'varus-cafe') {
      const slug = rootGetters['url/getCurrentRoute'].params.slug || 'varuscafe'
      return rootGetters['category-next/getCategoryByParams']({ slug: slug })
    }

    return rootGetters['category-next/getCategoryByParams']({ ...rootGetters['url/getCurrentRoute'].params })
  },
  getVarusCafeCategory: (state, getters, rootState, rootGetters) => {
    return rootGetters['category-next/getCategoryByParams']({ slug: 'varuscafe' })
  },
  getAvailableFiltersFrom: (state, getters, rootState) => (aggregations, category = null, attributeMetadata) => {
    const filters = {}
    if (aggregations) { // populate filter aggregates
      const data = [...attributeMetadata].reduce((a, c) => {
        if (!c.uri_name) return a

        a[c.attribute_code] = {
          uri_name: c.uri_name,
          options: {}
        }

        for (const option of c.options) {
          if (!option.uri_name) continue

          a[c.attribute_code].options[option.value] = option.uri_name
        }

        return a
      }, {})

      const categoryFilters = category?.filterattributes?.split(',')
      const defaultFilters = products.defaultFilters.concat(categoryFilters)
      for (const attrToFilter of defaultFilters) { // fill out the filter options
        const filterOptions: FilterVariant[] = []
        const uniqueFilterValues = new Set<string>()
        if (attrToFilter !== 'price') {
          if (aggregations['agg_terms_' + attrToFilter]) {
            let buckets = aggregations['agg_terms_' + attrToFilter].buckets
            if (aggregations['agg_terms_' + attrToFilter + '_options']) {
              buckets = buckets.concat(aggregations['agg_terms_' + attrToFilter + '_options'].buckets)
            }

            for (const option of buckets) {
              uniqueFilterValues.add(toString(option.key))
            }
          }

          uniqueFilterValues.forEach(key => {
            const label = optionLabel(rootState.attribute, { attributeKey: attrToFilter, optionId: key })
            if (trim(label) !== '') { // is there any situation when label could be empty and we should still support it?
              const group = data[attrToFilter]
              const option = group?.options?.[key]

              const uri = option ? {
                groupUri: group?.uri_name,
                optionUri: option
              } : {}

              filterOptions.push({
                id: key,
                label: label,
                type: attrToFilter,
                ...uri
              })
            }
          });
          filters[attrToFilter] = filterOptions.sort(compareByLabel)
        } else { // special case is range filter for prices
          if (aggregations['agg_' + attrToFilter]) {
            filters[attrToFilter] = aggregations['agg_' + attrToFilter]
          }
        }
      }
      // Add sort to available filters
      const variants = []
      Object.keys(products.sortByAttributes).map(label => {
        variants.push({
          label: label,
          id: products.sortByAttributes[label],
          type: 'sort'
        })
      })
      filters['sort'] = variants

      filters['available_today'] = [{
        label: attributes.filterAvailableToday.label,
        type: attributes.filterAvailableToday.type
      }];

      filters['markdown'] = [{
        label: attributes.filterMarkdown.label,
        type: attributes.filterMarkdown.type
      }];
    }

    return filters
  },
  getAvailableFiltersFromSearch: (state, getters, rootState) => (aggregations, aggregationsArr) => {
    const filters = {}
    const test = aggregationsArr.length > 0 ? aggregationsArr : products.defaultFilters
    if (aggregations) { // populate filter aggregates
      for (const attrToFilter of test) { // fill out the filter options
        const filterOptions: FilterVariant[] = []
        const uniqueFilterValues = []
        if (attrToFilter !== 'price') {
          if (aggregations['agg_terms_' + attrToFilter]) {
            let buckets = aggregations['agg_terms_' + attrToFilter].buckets
            if (aggregations['agg_terms_' + attrToFilter + '_options']) {
              buckets = buckets.concat(aggregations['agg_terms_' + attrToFilter + '_options'].buckets)
            }
            for (const option of buckets) {
              uniqueFilterValues.push(option)
            }
          }
          uniqueFilterValues.forEach(unique => {
            const label = optionLabel(rootState.attribute, { attributeKey: attrToFilter, optionId: toString(unique.key) })
            if (trim(label) !== '') { // is there any situation when label could be empty and we should still support it?
              filterOptions.push({
                id: toString(unique.key),
                label: label,
                type: attrToFilter,
                doc_count: toString(unique.doc_count)
              })
            }
          });
          filters[attrToFilter] = filterOptions.sort(compareByLabel)
        } else { // special case is range filter for prices
          const currencySign = currentStoreView().i18n.currencySign

          if (aggregations['agg_range_' + attrToFilter]) {
            let index = 0
            const count = aggregations['agg_range_' + attrToFilter].buckets.length
            for (const option of aggregations['agg_range_' + attrToFilter].buckets) {
              filterOptions.push({
                id: option.key,
                type: attrToFilter,
                from: option.from,
                to: option.to,
                label: (index === 0 || (index === count - 1)) ? (option.to ? '< ' + currencySign + option.to : '> ' + currencySign + option.from) : currencySign + option.from + (option.to ? ' - ' + option.to : ''), // TODO: add better way for formatting, extract currency sign
                single: true
              })
              index++
            }
            filters[attrToFilter] = filterOptions
          }
        }
      }
      // Add sort to available filters
      const variants = []
      Object.keys(products.sortByAttributes).map(label => {
        variants.push({
          label: label,
          id: products.sortByAttributes[label],
          type: 'sort'
        })
      })
      filters['sort'] = variants
    }

    return filters
  },
  getCurrentFiltersFrom: (state, getters, rootState) => (filters, categoryFilters, filtersHumanURI) => {
    const currentQuery = filters || rootState.route[products.routerFiltersSource]
    const availableFilters = categoryFilters || getters.getAvailableFilters

    return getFiltersFromQuery({ availableFilters, filtersQuery: currentQuery, filtersHumanURI })
  },
  getCurrentPromotionFiltersFrom: (state, getters, rootState) => (filters, categoryFilters) => {
    const currentQuery = filters || rootState.route[products.routerFiltersSource]
    const availableFilters = categoryFilters || getters.getAvailableFilters
    return getFiltersFromQuery({ availableFilters, filtersQuery: currentQuery })
  },
  getCurrentSearchQuery: (state, getters, rootState) => getters.getCurrentFiltersFrom(rootState.route[products.routerFiltersSource]),
  getCurrentFilters: (state, getters) => getters.getCurrentSearchQuery.filters,
  getCurrentFiltersHF: (state, getters, rootState) => (filtersHumanURI) => (
    getters.getCurrentFiltersFrom(rootState.route[products.routerFiltersSource], null, filtersHumanURI)
  ),
  hasActiveFilters: (state, getters) => !!Object.keys(getters.getCurrentFilters).length,
  getAggregations: (state) => state.aggregations,
  getFilterPrice: (state) => state.filterPrice,
  getVarusNewProducts: (state) => state.varusNewProducts,
  getNewProductsLoading: (state) => state.newProductsLoading,
  getIsBrandCategory: (state) => state.isBrandCategory,
  getNewProductsTotal: (state) => state.newProductsTotal,
  getClickLoadMore: (state) => state.clickLoadMore,
  getSpecialCategoryLoading: (state) => state.specialCategoryLoading
};

export default getters;
