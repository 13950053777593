import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import CategoryExtensionState from '../types/CategoryExtensionState'
import { buildFilterProductsQuery, prepareCategoryProduct } from 'theme/helpers'
import { isServer } from '@vue-storefront/core/helpers'
import config, { entities, products } from 'config'
import * as types from 'theme/store/category-extension/store/mutation-types'
import omit from 'lodash/omit'
import {
  CATEGORY_ADD_PRODUCTS,
  CATEGORY_SET_CATEGORY_FILTERS,
  CATEGORY_SET_PRODUCTS,
  CATEGORY_SET_SEARCH_PRODUCTS_STATS
} from '@vue-storefront/core/modules/catalog-next/store/category/mutation-types'
import { _prepareCategoryPathIds } from '@vue-storefront/core/modules/catalog-next/helpers/categoryHelpers'
import { changeFilterQuery } from '@vue-storefront/core/modules/catalog-next/helpers/filterHelpers'
import { parseCategoryPath } from '@vue-storefront/core/modules/breadcrumbs/helpers'
import FilterVariant from '@vue-storefront/core/modules/catalog-next/types/FilterVariant'
import i18n from '@vue-storefront/core/i18n'
import cloneDeep from 'lodash/cloneDeep'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import { SearchQuery } from 'storefront-query-builder'
import { router } from '@vue-storefront/core/app'
import { getCurrentShopId } from 'theme/store/checkout/helpers'
import createCategoryListQuery from '@vue-storefront/core/modules/catalog/helpers/createCategoryListQuery';
import { getCategoryFromRoute, getPromotionFilter, getFindProductProps } from '../../category-extension/helpers'
import { DataResolver } from '@vue-storefront/core/data-resolver/types/DataResolver';
import { Category } from '@vue-storefront/core/modules/catalog-next/types/Category';
import { CategoryService } from '@vue-storefront/core/data-resolver';
import modulesConfig from '$modules/config'
import { promotionFilterQuery } from 'theme/store/category-extension/helpers/promotionFilter';
import { clearRegionUrl } from '$modules/shipping/utils/region-url';

const getSearchQueryAggregationCategories = (ids: number[]): SearchQuery => {
  const filterQuery = new SearchQuery()

  if (ids.length) {
    filterQuery
      .applyFilter({ key: 'category_ids', value: { 'in': ids } })
  }

  filterQuery
    .applyFilter({ key: 'visibility', value: { 'in': [2, 4] } })
    .applyFilter({ key: 'status', value: { 'in': [0, 1] } })
    .addAvailableFilter({
      field: 'category_ids',
      scope: 'catalog',
      options: {
        size: 10000
      }
    })

  return filterQuery
}

const actions: ActionTree<CategoryExtensionState, RootState> = {
  async specialCategoriesIds ({ dispatch }) {
    const paths = [
      'categories_map_perfect_category_id',
      'categories_map_discount_category_id',
      'categories_map_brand_category_id'
    ]

    const config = await dispatch('config-varus/get', { path: paths }, { root: true })

    return {
      varusPerfectCategoryId: parseInt(config['categories_map_perfect_category_id']),
      specialOffersCategoryId: parseInt(config['categories_map_discount_category_id']),
      productBrandId: parseInt(config['categories_map_brand_category_id'])
    }
  },
  async loadCategoryProducts (
    {
      commit,
      getters,
      dispatch,
      rootGetters
    },
    {
      route,
      category,
      pageSize = 50,
      start = 0
    } = {}
  ) {
    const path = clearRegionUrl(route.path)
    let searchCategory = category || rootGetters['category-next/getCategoryFrom'](path) || {}
    const shopId = await getCurrentShopId()

    const {
      varusPerfectCategoryId,
      specialOffersCategoryId,
      productBrandId
    } = await dispatch('specialCategoriesIds')

    const isBrandCategory = productBrandId === category.parent_id;

    commit(types.SET_IS_BRAND_CATEGORY, isBrandCategory)

    if (route.query && route.query.page) {
      start = (route.query.page - 1) * pageSize;
    } else if (route.params && route.params.page) {
      start = (route.params.page - 1) * pageSize;
    }

    const categoryMappedFilters = getters.getFiltersMap[searchCategory.id]

    const routerFiltersSource = route[products.routerFiltersSource]

    const areFiltersInQuery = !!Object.keys(routerFiltersSource).length
    if (!categoryMappedFilters && areFiltersInQuery) { // loading all filters only when some filters are currently chosen and category has no available filters yet
      await dispatch('loadCategoryFilters', searchCategory)
    }
    let searchQuery = getters.getCurrentFiltersFrom(routerFiltersSource, categoryMappedFilters)
    if (areFiltersInQuery) {
      for (const routerFilter in routerFiltersSource) {
        if (routerFiltersSource.hasOwnProperty(routerFilter) && routerFilter === config.attributes.filterIsPromo.type) {
          searchQuery = {
            ...searchQuery,
            filters: {
              ...searchQuery.filters,
              attribute_code: routerFilter
            }
          }
        }
      }
    }
    const paramSearch = searchQuery.filters

    if ((varusPerfectCategoryId === category.id || specialOffersCategoryId === category.id || isBrandCategory) && routerFiltersSource.hasOwnProperty('cat')) {
      const filters = { id: 0 }
      if (Array.isArray(routerFiltersSource.cat)) {
        filters.id = Number(routerFiltersSource.cat[routerFiltersSource.cat.length - 1])
      } else {
        filters.id = Number(routerFiltersSource.cat)
      }
      searchCategory = await dispatch('category-next/loadCategory', { filters }, { root: true })
    }

    if ((varusPerfectCategoryId === category.id || specialOffersCategoryId === category.id || isBrandCategory) && routerFiltersSource.hasOwnProperty('cat')) {
      category.filterattributes = searchCategory.filterattributes
    }

    let filterQr = buildFilterProductsQuery(searchCategory, paramSearch, null, '', [specialOffersCategoryId], shopId)

    const filterPrice = rootGetters['category-extension/getFilterPrice']

    if (searchQuery.filters.hasOwnProperty('price')) {
      if (Object.keys(filterPrice).length > 0) {
        filterQr.applyFilter({ key: `sqpp_data_${shopId}.sort_price`,
          value: {
            'gte': filterPrice.from,
            'lte': filterPrice.to
          },
          scope: 'default' })
      } else {
        filterQr.applyFilter({ key: `sqpp_data_${shopId}.sort_price`,
          value: {
            'gte': route[config.products.routerFiltersSource].price.split('-')[0],
            'lte': route[config.products.routerFiltersSource].price.split('-')[1]
          },
          scope: 'default' })
      }
    }
    if (varusPerfectCategoryId === category.id || specialOffersCategoryId === category.id || isBrandCategory) {
      filterQr = filterQr.addAvailableFilter({ field: 'category_ids', scope: 'catalog' })
    }
    if ((varusPerfectCategoryId === category.id || isBrandCategory) &&
        routerFiltersSource.hasOwnProperty('cat')) {
      filterQr = filterQr.applyFilter(
        {
          key: isBrandCategory ? 'pim_brand_id' : 'category_id',
          value: { 'eq': isBrandCategory ? category.pim_brand_id : category.id },
          scope: 'default'
        })
    }

    filterQr = filterQr.addAvailableFilter({ field: 'promotion_banner_ids', scope: 'catalog' })
    filterQr = filterQr.addAvailableFilter({ field: 'price',
      scope: 'catalog',
      options: {
        'shop_id': shopId,
        'version': '2'
      } })

    filterQr = filterQr.addAvailableFilter({
      field: 'has_promotion_in_stores',
      scope: 'catalog',
      options: {
        size: 10000
      }
    })

    if (specialOffersCategoryId === category.id && shopId) {
      filterQr = filterQr.applyFilter(promotionFilterQuery(shopId))
    }

    const availableToday = searchQuery.hasOwnProperty('available_today')

    const { items, perPage, total, aggregations, attributeMetadata } = await dispatch('product/findProducts', {
      query: filterQr,
      sort: searchQuery.sort || routerFiltersSource.sort || `${products.defaultSortBy.attribute}:${products.defaultSortBy.order}`,
      start,
      includeFields: modulesConfig.smallProduct.includeFields,
      excludeFields: modulesConfig.smallProduct.excludeFields,
      skipLoadOptions: true,
      size: pageSize,
      availableToday: availableToday,
      options: {
        populateRequestCacheTags: true,
        prefetchGroupProducts: false,
        setProductErrors: false,
        fallbackToDefaultWhenNoAvailable: true,
        assignProductConfiguration: false,
        separateSelectedVariant: false
      }
    }, { root: true })

    const toPromise = [
      dispatch('category-extension/loadAvailableFiltersFrom', {
        aggregations,
        attributeMetadata,
        category: category,
        filters: searchQuery.filters
      }, { root: true })
    ]

    if (varusPerfectCategoryId === category.id || specialOffersCategoryId === category.id || isBrandCategory) {
      toPromise.push(
        dispatch('setCategories', category)
      )
    }

    await Promise.all(toPromise)

    if (varusPerfectCategoryId === category.id || specialOffersCategoryId === category.id || isBrandCategory) {
      category.filterattributes = ''
    }

    commit(`category-next/${CATEGORY_SET_SEARCH_PRODUCTS_STATS}`, { perPage, start, total }, { root: true })
    commit(`category-next/${CATEGORY_SET_PRODUCTS}`, items, { root: true })
    commit(types.SET_PRODUCT_AGGREGATION, aggregations)

    return items
  },
  /**
   * Fetch and process filters from current category and sets them in available filters.
   */
  async loadCategoryFilters ({ dispatch, rootGetters }, category) {
    const searchCategory = category || rootGetters['category-next/getCurrentCategory']

    const {
      specialOffersCategoryId
    } = await dispatch('specialCategoriesIds')

    const shopId = await getCurrentShopId()

    const filterQr = buildFilterProductsQuery(searchCategory, {}, null, '', [specialOffersCategoryId], shopId)

    const { aggregations, attributeMetadata } = await quickSearchByQuery({
      query: filterQr,
      size: config.products.maxFiltersQuerySize,
      excludeFields: ['*']
    })
    await dispatch('category-extension/loadAvailableFiltersFrom', {
      aggregations, attributeMetadata: attributeMetadata, category
    }, { root: true })
  },
  async loadChildCategories ({ commit, dispatch }, { category, pageSize }) {
    if (!category.children_data || !category.children_data.length) {
      return
    }

    try {
      const loadProductPromises = category.children_data.map(child =>
        dispatch('loadTopProducts', { category: child, pageSize })
          .then(products => {
            const processedProducts = products.map(prepareCategoryProduct);
            return { ...child, products: processedProducts };
          })
      );

      const categories = await Promise.all(loadProductPromises);

      commit(types.SET_CHILD_CATEGORIES, categories);
    } catch (error) {
      console.error('Failed to load child categories:', error);
    }
  },

  async loadRelatedChildCategories ({ commit }, { category, relatedProducts = [] }) {
    if (!category.children_data || !category.children_data.length || !relatedProducts.length) {
      return
    }

    const categories = [];

    for (const child of category.children_data) {
      const products = relatedProducts.filter(e => e.category_ids?.includes(child.id))
      categories.push(Object.assign({}, child, { products }))
    }
    commit(types.SET_RELATED_CHILD_CATEGORIES, categories)
  },

  async loadTopProducts ({ dispatch }, { category, pageSize = 25 }) {
    const query = buildFilterProductsQuery(category)

    const { items } = await dispatch('product/findProducts', {
      query,
      sort: `${products.defaultSortBy.attribute}:${products.defaultSortBy.order}`,
      includeFields: modulesConfig.smallProduct.includeFields,
      excludeFields: modulesConfig.smallProduct.excludeFields,
      skipLoadOptions: true,
      size: pageSize,
      options: {
        populateRequestCacheTags: true,
        prefetchGroupProducts: false,
        setProductErrors: false,
        fallbackToDefaultWhenNoAvailable: true,
        assignProductConfiguration: false,
        separateSelectedVariant: false
      },
      onlyInStock: true
    }, { root: true })

    return items
  },

  async switchSearchFilters ({ dispatch }, filterVariants: FilterVariant[] = []) {
    const DEFAULT_CATEGORY_SORT = config.products.sortByAttributes.Popularity;

    let currentQuery = router.currentRoute[products.routerFiltersSource];
    filterVariants.forEach(filterVariant => {
      if (currentQuery.hasOwnProperty('price') && filterVariant['attribute_code'] === 'price') {
        delete currentQuery.price
      }
      if (currentQuery.hasOwnProperty('cat') && filterVariant['type'] === 'cat') {
        delete currentQuery.cat
      }

      if (filterVariant['type'] === 'cat') {
        currentQuery = changeFilterQuery({ currentQuery: {}, filterVariant });
      } else if (currentQuery.hasOwnProperty('has_promotion_in_stores') && filterVariant['type'] === 'has_promotion_in_stores') {
        currentQuery = changeFilterQuery({ currentQuery, filterVariant });
      } else {
        currentQuery = changeFilterQuery({ currentQuery, filterVariant });
      }
    });

    if (currentQuery.sort === DEFAULT_CATEGORY_SORT && currentQuery.hasOwnProperty('sort')) delete currentQuery.sort;

    await dispatch('changeRouterFilterParameters', currentQuery);
  },
  async resetSearchFilters ({ dispatch, getters }, { keepSort = false }) {
    if (keepSort && getters.getCurrentSearchQuery.sort) {
      if (Object.keys(getters.getCurrentSearchQuery).length > 0) {
        return dispatch('changeRouterFilterParameters', { sort: getters.getCurrentSearchQuery.sort });
      } else {
        await dispatch('changeRouterFilterParameters', {});
      }
    }

    await dispatch('changeRouterFilterParameters', {});
  },
  async changeRouterFilterParameters (context, query) {
    if ('page' in query) {
      delete query['page'];
    }

    await router.push({ [products.routerFiltersSource]: query });
  },
  async loadCategoryBreadcrumbs ({ dispatch }, { category, currentRouteName, omitCurrent = false }) {
    if (!category) {
      return
    }

    const specialOffersCategoryId = parseInt(await dispatch('config-varus/get', { path: 'categories_map_discount_category_id' }, { root: true }))

    if (specialOffersCategoryId === category.id) {
      await dispatch('breadcrumbs/set', {
        current: i18n.t('Special offers VARUS'),
        routes: []
      }, { root: true })
      return
    }

    const categoryHierarchyIds = category.parent_ids ? [...category.parent_ids, category.id] : _prepareCategoryPathIds(category)
    const categoryFilters = Object.assign({ 'id': categoryHierarchyIds }, cloneDeep(entities.category.breadcrumbFilterFields))
    const categories = await dispatch(
      'category-next/loadCategories',
      { filters: categoryFilters, reloadAll: Object.keys(entities.category.breadcrumbFilterFields).length > 0 },
      { root: true }
    )
    const sorted = []
    for (const id of categoryHierarchyIds) {
      if (id === entities.category.categoriesRootCategoryId.toString()) {
        continue;
      }

      const index = categories.findIndex(cat => cat.id.toString() === id)
      if (index >= 0 && (!omitCurrent || categories[index].id !== category.id)) {
        sorted.push(categories[index])
      }
    }

    await dispatch('breadcrumbs/set', {
      current: currentRouteName,
      routes: parseCategoryPath(sorted)
    }, { root: true })

    return sorted
  },

  async loadCategoriesProductsCount ({ dispatch, commit }, rootCategory) {
    if (!rootCategory || !rootCategory.children_data) {
      return
    }

    const result = {};

    const { aggregations } = await dispatch('product/findProducts', {
      query: getSearchQueryAggregationCategories([rootCategory.id]),
      size: 1,
      excludeFields: ['*']
    }, { root: true });

    const arrayCategories = aggregations?.agg_terms_category_ids?.buckets;
    arrayCategories.forEach(bucket => {
      result[bucket.key] = bucket.doc_count
    })

    commit(types.SET_CATEGORIES_PRODUCTS_COUNT, result)
  },
  async loadIsCategoryProductsOutOfStock ({ dispatch, commit }, { category }) {
    if (!category) return;

    const query = getSearchQueryAggregationCategories([category.id])

    const { total } = await dispatch('product/findProducts', {
      query,
      size: 1,
      excludeFields: ['*'],
      onlyInStock: true
    }, { root: true });

    commit(types.SET_IS_CATEGORY_PRODUCTS_OUT_OF_STOCK, !total.value);
  },
  async loadCharacteristicAttributes (ctx, { attributes, options }) {
    const query = new SearchQuery();

    query
      .applyFilter({
        key: 'attribute_code',
        value: { 'in': [...new Set(attributes)] }
      })

    const { items } = await quickSearchByQuery({
      query,
      size: attributes.length,
      entityType: 'attribute',
      includeFields: [
        'attribute_code',
        'uri_name',
        'options'
      ]
    })

    return (items || []).reduce((a, c) => {
      if (!c.options || !c.uri_name) return a

      const result = (c.options || [])
        .filter(o => (
          !!options.find(i => (
            c.uri_name === i.groupUri &&
            o.uri_name === i.optionUri
          ))
        ))
        .map(o => ({
          id: o.value,
          label: o.label,
          type: c.attribute_code,
          groupUri: c.uri_name,
          optionUri: o.uri_name
        }))

      if (result.length) a[c.attribute_code] = result

      return a
    }, {})
  },
  async prepareHumanFilters ({
    dispatch
  }, {
    category,
    filtersHuman
  }) {
    if (!category?.filterattributes) return {}

    const options = filtersHuman.getUriOptions()
    const attributes = (category?.filterattributes || '').split(',')

    if (!attributes.length || !options.length) return {}

    return dispatch('loadCharacteristicAttributes', { attributes, options })
  },
  async loadAvailableFiltersFrom ({ commit, rootGetters, dispatch }, { aggregations, attributeMetadata, category, filters = {} }) {
    if (config.entities.attribute.loadByAttributeMetadata) {
      await dispatch('attribute/loadCategoryAttributes', { attributeMetadata }, { root: true })
    }

    const aggregationFilters = rootGetters['category-extension/getAvailableFiltersFrom'](aggregations, category, attributeMetadata)

    const categoryMappedFilters = rootGetters['category-extension/getFiltersMap'][category.id]

    let resultFilters = aggregationFilters
    const filtersKeys = Object.keys(filters)
    if (categoryMappedFilters && filtersKeys.length) {
      resultFilters = Object.assign(cloneDeep(categoryMappedFilters), cloneDeep(omit(aggregationFilters, filtersKeys)))
    }

    commit(types.CATEGORY_SET_CATEGORY_FILTERS, { category, filters: resultFilters })
    commit(`category-next/${CATEGORY_SET_CATEGORY_FILTERS}`, { category, filters: resultFilters }, { root: true })
  },
  async getCategoriesByChunk ({ dispatch }, { chunkSize = 200, categoryIdsArray = [] }) {
    if (!categoryIdsArray || !categoryIdsArray.length) return []

    const chunks = Math.ceil(categoryIdsArray.length / chunkSize)

    const toPromise = []

    for (let i = 0; i < chunks; i++) {
      const filter = { id: categoryIdsArray.slice(i * chunkSize, (i + 1) * chunkSize) }

      toPromise.push(
        dispatch(
          'category-next/loadCategories',
          { filters: filter },
          { root: true }
        )
      )
    }

    const categoryChunk = await Promise.all(toPromise)
    const categoryArray = categoryChunk.reduce((a, c) => a.concat(c), [])

    return categoryArray
  },
  async setCategories ({ commit, dispatch }, category) {
    commit(types.SET_SPECIAL_CATEGORY_LOADING, true)

    const {
      specialOffersCategoryId
    } = await dispatch('specialCategoriesIds')

    const shopId = await getCurrentShopId()

    let filterQr = buildFilterProductsQuery(category, {}, null, '', [specialOffersCategoryId], shopId)
    filterQr = filterQr.addAvailableFilter({ field: 'has_promotion_in_stores', scope: 'catalog' })

    if (specialOffersCategoryId === category.id && shopId) {
      filterQr = filterQr.applyFilter(promotionFilterQuery(shopId))
    }

    filterQr = filterQr.addAvailableFilter({ field: 'category_ids', scope: 'catalog' })

    const { aggregations } = await quickSearchByQuery({
      query: filterQr
    })

    const categoryIds = aggregations.agg_terms_category_ids.buckets

    const categoryIdsArray = categoryIds.map(elem => elem.key)

    const categoryArray = await dispatch('getCategoriesByChunk', { categoryIdsArray })

    const categories = categoryArray.filter(category => {
      return categoryIds.some(categoryId => {
        if (category.id === categoryId.key) {
          category.countProduct = categoryId.doc_count
        }
        return category.id === categoryId.key
      })
    }).filter(cat => cat.id !== 14779 && cat.id !== 2 && cat.id !== 470 && cat.parent_id !== 470)

    commit(types.SET_SPECIAL_CATEGORY_LOADING, false)
    commit(types.CATEGORY_SET_RESULT_CATEGORIES, categories)
  },

  async setCategory ({ commit }, category) {
    commit(types.SET_CURRENT_CATEGORY, category)
  },
  async filterCategoryPrice ({ commit }, filter) {
    commit(types.SET_FILTER_PRICE, filter)
  },

  async loadMoreCategoryProducts ({ commit, getters, rootGetters, dispatch }, { route, currentCategory }) {
    const { perPage, start, total } = rootGetters['category-next/getCategorySearchProductsStats']
    const totalValue = typeof total === 'object' ? total.value : total
    if (start >= totalValue || totalValue < perPage) return

    const searchQuery = getters.getCurrentSearchQuery

    const {
      specialOffersCategoryId
    } = await dispatch('specialCategoriesIds')

    const shopId = await getCurrentShopId()

    const filterQr = buildFilterProductsQuery(currentCategory, searchQuery.filters, null, '', [specialOffersCategoryId], shopId)

    const categoryId = getCategoryFromRoute({ route, products })

    if (categoryId) {
      const searchCategory = await dispatch('category-next/loadCategory', { filters: { id: categoryId } }, { root: true })

      filterQr.applyFilter(
        {
          key: 'category_id',
          value: { 'eq': searchCategory.id },
          scope: 'default'
        }
      )
    }

    const promotionFilter = getPromotionFilter(route, shopId);
    if (promotionFilter) {
      filterQr.applyFilter(promotionFilter)
    }

    filterQr.addAvailableFilter({ field: 'has_promotion_in_stores', scope: 'catalog' })

    if (specialOffersCategoryId === currentCategory.id && shopId) {
      filterQr.applyFilter(promotionFilterQuery(shopId))
    }

    const searchResult = await dispatch('product/findProducts', getFindProductProps({
      query: filterQr,
      sort: searchQuery.sort || `${products.defaultSortBy.attribute}:${products.defaultSortBy.order}`,
      start: start + perPage,
      size: perPage,
      configuration: searchQuery.filters
    }), { root: true })

    commit(`category-next/${CATEGORY_SET_SEARCH_PRODUCTS_STATS}`, {
      perPage: searchResult.perPage,
      start: searchResult.start,
      total: searchResult.total
    }, { root: true })

    commit(`category-next/${CATEGORY_ADD_PRODUCTS}`, searchResult.items, { root: true })

    return searchResult.items
  },
  async loadVarusCafe ({ commit, dispatch }) {
    try {
      commit(types.SET_VARUS_CAFE_CATEGORIES_LOADING, true)

      const parent = parseInt(await dispatch('config-varus/get', { path: 'categories_map_cafe_category_id' }, { root: true }))
      const level = config.entities.category.categoriesDynamicPrefetch &&
      config.entities.category.categoriesDynamicPrefetchLevel >= 0
        ? config.entities.category.categoriesDynamicPrefetchLevel
        : null

      const includeFields = config.entities.optimize && isServer
        ? config.entities.category.includeFields
        : null

      const excludeFields = config.entities.optimize ? config.entities.category.excludeFields : null

      const { searchQuery } = createCategoryListQuery({
        parent,
        level,
        key: null,
        value: null,
        onlyActive: true,
        onlyNotEmpty: false
      })

      const resp = await quickSearchByQuery({
        entityType: 'category',
        query: searchQuery,
        sort: 'position:asc',
        size: 4000,
        start: 0,
        includeFields,
        excludeFields
      })

      commit(types.SET_VARUS_CAFE_CATEGORIES, resp.items)

      return resp
    } finally {
      commit(types.SET_VARUS_CAFE_CATEGORIES_LOADING, false)
    }
  },
  async loadVarusNewProducts ({ dispatch, commit }, {
    currentPage = 1,
    pageSize = 24,
    loadMore = false
  }): Promise<void> {
    const searchQuery = new SearchQuery();
    const query = searchQuery.applyFilter({ key: 'is_new', value: { 'eq': true }, scope: 'default' });
    query.applySort({ field: 'updated_at', options: { order: 'desc' } })
    query.applyFilter({ key: 'news_from_date', value: { 'lt': 'now' } })
    query.applyFilter({ key: 'news_to_date', value: { 'gt': 'now-1d' } })

    commit(types.SET_VARUS_NEW_PRODUCTS_LOADING, true);

    const { items, total } = await dispatch('product/findProducts', {
      query,
      start: (currentPage - 1) * pageSize,
      size: pageSize,
      includeFields: [...modulesConfig.smallProduct.includeFields, 'newimage'],
      excludeFields: modulesConfig.smallProduct.excludeFields,
      skipLoadOptions: true
    }, { root: true });

    if (loadMore) {
      commit(types.ADD_VARUS_NEW_PRODUCTS, items);
    } else {
      commit(types.SET_VARUS_NEW_PRODUCTS, items);
    }

    commit(types.SET_VARUS_NEW_PRODUCTS_TOTAL, total.value);
    commit(types.SET_VARUS_NEW_PRODUCTS_LOADING, false);
  },
  async clickLoadMore ({ commit }, { enable }) {
    commit(types.SET_CLICK_LOAD_MORE, enable);
  },
  async loadCategoryPure (ctx, categorySearchOptions: DataResolver.CategorySearchOptions): Promise<Category> {
    const categories: Category[] = await CategoryService.getCategories(categorySearchOptions)
    return categories && categories.length ? categories[0] : null
  }
}

export default actions
