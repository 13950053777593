import * as types from '@vue-storefront/core/modules/cart/store/mutation-types'

import {
  createDiffLog,
  prepareProductsToAdd,
  validateProduct
} from '@vue-storefront/core/modules/cart/helpers'
import { cartHooksExecutors } from '@vue-storefront/core/modules/cart/hooks';
import * as notifications from 'theme/store/cart/helpers/notifications'

const itemActions = {
  async addItem ({ dispatch, commit }, {
    productToAdd,
    forceServerSilence = false
  }) {
    const { cartItem } = cartHooksExecutors.beforeAddToCart({ cartItem: productToAdd })
    commit(types.CART_ADDING_ITEM, { isAdding: true })

    const result = await dispatch('addItems', { productsToAdd: prepareProductsToAdd(cartItem), forceServerSilence })
    commit(types.CART_ADDING_ITEM, { isAdding: false })
    cartHooksExecutors.afterAddToCart(result)
    return result
  },
  async addItems ({ commit, dispatch, getters }, { productsToAdd, forceServerSilence = false }) {
    const diffLog = createDiffLog()

    for (const product of productsToAdd) {
      const errors = validateProduct(product)
      diffLog.pushNotifications(notifications.createNotifications({ type: 'error', messages: errors }))

      if (errors.length === 0) {
        commit(types.CART_ADD_ITEM, {
          product: {
            ...product,
            extension_attributes: {
              ...product.extension_attributes,
              list_id: product.extension_attributes?.list_id,
              list_name: product.extension_attributes?.list_name
            }
          }
        })
      }
    }

    const newDiffLog = await dispatch('create')
    if (newDiffLog !== undefined) {
      diffLog.merge(newDiffLog)
    }

    if (getters.isCartSyncEnabled && !forceServerSilence) {
      const syncDiffLog = await dispatch('sync', { forceClientState: true, forceSync: true, skipPull: true })

      if (!syncDiffLog.isEmpty()) {
        diffLog.merge(syncDiffLog)
      }
    }

    return diffLog
  },
  async addProductToCart ({ dispatch }, product) {
    try {
      const diffLog = await dispatch('addItem', {
        productToAdd: product
      })
      diffLog.clientNotifications.forEach(notificationData => {
        dispatch(
          'notification/spawnNotification',
          notificationData,
          { root: true }
        );
      });
    } catch (message) {
      await dispatch(
        'notification/spawnNotification',
        notifications.createNotification({ type: 'danger', message }),
        { root: true }
      );
    }
  }
}

export default itemActions
