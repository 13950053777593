import * as coreTypes from '@vue-storefront/core/modules/cart/store/mutation-types'
import * as types from '../mutation-types'
import { Logger } from '@vue-storefront/core/lib/logger'
import config from 'config'
import { createDiffLog } from '@vue-storefront/core/modules/cart/helpers'
import { cartHooksExecutors } from '@vue-storefront/core/modules/cart/hooks'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import { emitTabEvent } from 'theme/store/cart/helpers/tabEvent'
import { addToQueue, clearSyncQueue, hasInQueue, SKIP_SYNC, syncQueue } from 'theme/store/cart/helpers/syncQueue'
import { SyncPayload } from 'theme/store/cart/types/SyncTypes'
import { prepareClientItems } from 'theme/store/cart/helpers/updateClientItems'
import { UserCartService } from 'theme/store/cart/data-resolver/UserCartService'
import { isServer } from '@vue-storefront/core/helpers'
import { getDiscountDate } from 'theme/helpers';
import { LANGUAGE_KEY_CHANGED } from 'theme/helpers/language';
import { CartExtResult } from 'theme/store/cart/types/CartState';
import { groups, sendToLogs } from 'theme/helpers/web-logging';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';
import { getMinSalableValue } from 'theme/store/checkout/helpers';
import { isSkuEqual } from '@vue-storefront/core/modules/cart/helpers/productsEquals';

const synchronizeActions = {
  async load ({ commit, dispatch }) {
    if (isServer) return

    dispatch('setDefaultCheckoutMethods')
    const storedItems = await StorageManager.get('cart').getItem('current-cart')

    commit(coreTypes.CART_LOAD_CART, storedItems)
    dispatch('synchronizeCart')

    cartHooksExecutors.afterLoad(storedItems)
  },
  async synchronizeCart ({ commit, dispatch }) {
    const { synchronize } = config.cart
    if (!synchronize) return
    const cartStorage = StorageManager.get('cart')
    const token = await cartStorage.getItem('current-cart-token')
    const hash = await cartStorage.getItem('current-cart-hash')

    if (hash) {
      commit(coreTypes.CART_SET_ITEMS_HASH, hash)
      Logger.info('Cart hash received from cache.', 'cache', hash)()
    }
    if (token) {
      dispatch('setCartToken', { token })
      Logger.info('Cart token received from cache.', 'cache', token)()
      Logger.info('Syncing cart with the server.', 'cart')()
    }
    await dispatch('create', { sync: true })
  },
  async setCartToken ({ commit }, { token, emit = true }) {
    commit(coreTypes.CART_LOAD_CART_SERVER_TOKEN, token)

    if (emit) {
      emitTabEvent(token, 'cartQuoteUpdate')
    }
  },
  async sync ({ dispatch, state }, params: SyncPayload) {
    if (isServer) return createDiffLog()

    if (state.isSyncing) {
      return addToQueue(params)
    }

    return syncQueue(dispatch, params)
  },
  async syncHandler ({
    getters,
    rootState,
    commit,
    dispatch
  }, payload: SyncPayload) {
    const {
      keepCart = false,
      forceClientState = false,
      dryRun = false,
      mergeQty = false,
      forceSync = false,
      forceShippingSave = false,
      skipPull = false
    } = payload

    try {
      await dispatch('updateCartSyncing', true)

      const shouldUpdateClientState = forceClientState
      const { getCartItems, canUpdateMethods, isSyncRequired } = getters

      await dispatch('refreshCartItems', getCartItems)

      const blocked = (!canUpdateMethods || !isSyncRequired) && !forceSync && !forceShippingSave

      if (blocked || rootState.ui.isOrderPreparingToProcess) {
        return createDiffLog()
      }

      commit(coreTypes.CART_SET_SYNC)

      const { result, resultCode, totals, toShippingSave, transparentId } = await dispatch('cartPull', { forceShippingSave, skipPull })

      if (hasInQueue(payload)) {
        return SKIP_SYNC
      }

      const clientItemsRemap = getCartItems.length
        ? prepareClientItems(getCartItems, result)
        : getCartItems

      if (resultCode === 200) {
        await dispatch('checkProductsPrices', result)
      } else {
        sendToLogs(
          groups.Cart,
          'syncHandler:pull:not:200',
          { code: resultCode },
          transparentId
        )
      }

      if (resultCode === 401) {
        await dispatch('user/logout', { silent: true, sync: true }, { root: true })

        return createDiffLog()
      }

      const { serverItems, clientItems } = cartHooksExecutors.beforeSync({
        clientItems: clientItemsRemap,
        serverItems: result
      })

      if (resultCode === 200) {
        const { diffLog, totalsAfterMerge, itemsAfterMerge } = await dispatch('merge', {
          dryRun,
          serverItems,
          clientItems,
          keepCart,
          forceClientState: shouldUpdateClientState,
          mergeQty,
          toShippingSave,
          revertRule: getters.revertRule
        })

        const afterMerge = (itemsAfterMerge || [])
        const afterMergeKeys = afterMerge.map(i => i.sku)

        const prepareList = (serverItems || []).filter(i => !afterMergeKeys.includes(i.sku))

        if (afterMerge.length) prepareList.push(...afterMerge)

        const qtyCorrectionList = prepareList?.map(i => ({
          clientItem: getCartItems.find(p => isSkuEqual(p, i)),
          serverItem: i
        })) || []

        const qtyCorrection = qtyCorrectionList.filter(({ serverItem, clientItem }) => {
          if (!clientItem) return false

          const minSalableQty = getMinSalableValue(clientItem)
          const serverQty = serverItem?.extension_attributes?.sqpp_data?.qty

          return (
            serverQty && serverItem?.qty > serverQty && serverQty > minSalableQty
          )
        })

        if (qtyCorrection.length) {
          for (const item of qtyCorrection) {
            commit(coreTypes.CART_UPD_ITEM_SAFE, {
              product: item.clientItem,
              qty: item.serverItem.extension_attributes.sqpp_data.qty
            })
          }

          addToQueue({ forceClientState: true }, true)
        }

        dispatch('cart/processCartSQPP', itemsAfterMerge || result, { root: true })
        dispatch('cart/overrideTotalsFromPayload', {
          totals: totalsAfterMerge || totals,
          result: itemsAfterMerge || result
        }, { root: true })

        await dispatch('checkout/availableBonuses', getters.getCartToken, { root: true })

        emitTabEvent({ clientItems, totals: totalsAfterMerge || totals }, 'cartSyncUpdate')

        cartHooksExecutors.afterSync(diffLog)

        return diffLog
      }

      Logger.error(result, 'cart')
      cartHooksExecutors.afterSync(result)
      commit(coreTypes.CART_SET_ITEMS_HASH, getters.getCurrentCartHash)

      return createDiffLog()
    } catch (e) {
      sendToLogs(
        groups.Cart,
        'syncHandler:catch:error',
        { message: e.message }
      )
    } finally {
      EventBus.$emit('cart-after-sync')
      await dispatch('updateCartSyncing', false)
    }
  },
  async refreshCartItemsHandler ({ dispatch }, storedItems) {
    try {
      if (!storedItems.length) {
        return []
      }

      const isLanguageChanged = localStorage.getItem(LANGUAGE_KEY_CHANGED) !== null

      if (isLanguageChanged) {
        localStorage.removeItem(LANGUAGE_KEY_CHANGED)

        const productsSku = storedItems.map(i => i.sku)

        return dispatch('product/getSimpleProductsBySku', {
          skus: productsSku
        }, { root: true });
      }

      const uaTime = new Date().toLocaleString('en-US', {
        timeZone: 'Europe/Kiev'
      })

      const now = new Date(uaTime)

      const nowObj = {
        day: now.getDate(),
        month: now.getMonth() + 1,
        year: now.getFullYear()
      }

      const shouldUpdate = storedItems.filter(i => {
        const { date } = getDiscountDate(i)

        if (!date) {
          return false
        }

        return nowObj.day > +date.day &&
          nowObj.month >= +date.month &&
          nowObj.year >= +date.year
      }).map(i => i.sku)

      if (!shouldUpdate.length) {
        return []
      }

      const updated = await dispatch('product/getProductsBySku', {
        skus: shouldUpdate
      }, { root: true });

      const notUpdated = storedItems.filter(i => !shouldUpdate.includes(i.sku))

      return [
        ...updated,
        ...notUpdated
      ]
    } catch (e) {
      Logger.error(e)();

      return []
    }
  },
  async refreshCartItems ({ commit, dispatch }, storedItems) {
    const items = await dispatch('refreshCartItemsHandler', storedItems)

    if (!items.length) {
      return
    }

    const itemsByOrder = storedItems.map((itemServe) => {
      const item = items.find(i => isSkuEqual(i, itemServe.sku))

      return {
        ...itemServe,
        ...item,
        qty: itemServe.qty
      }
    })

    commit(coreTypes.CART_LOAD_CART, itemsByOrder)

    return StorageManager.get('cart').setItem('current-cart', itemsByOrder).catch((reason) => {
      Logger.error(reason)()
    })
  },
  async clearCartSyncing ({ commit }) {
    try {
      commit(types.CART_SET_IS_SYNCING, false)
      commit(types.CART_SET_LAST_PULL, null)

      clearSyncQueue()

      await StorageManager.get('cart').removeItem(config.cart.cartIsSyncing);
    } catch (e) {
      Logger.error(e, 'cart')();
    }
  },

  async updateCartSyncing ({ commit }, bool) {
    commit(types.CART_SET_IS_SYNCING, bool);

    try {
      await StorageManager.get('cart').setItem(config.cart.cartIsSyncing, bool);
    } catch (e) {
      Logger.error(e, 'cart')();
    }
  },

  async loadCartIsSyncing ({ commit }) {
    try {
      const isCartMergeConfirmed = await StorageManager.get('cart').getItem(config.cart.cartIsSyncing);
      if (isCartMergeConfirmed) commit(types.CART_SET_IS_SYNCING, true);
    } catch (e) {
      Logger.error(e)();
    }
  },

  async disconnect ({ dispatch }, payload) {
    const emit = payload ? payload.emit : true
    dispatch('setCartToken', { token: null, emit })
  },

  async clear ({ commit, dispatch, getters }, { disconnect = true, sync = true } = {}) {
    await commit(coreTypes.CART_LOAD_CART, [])
    commit(types.CART_SET_LAST_PULL, null)

    if (sync) {
      const cartToken = getters['getCartToken']

      await UserCartService.cartClear(cartToken)

      await dispatch('syncTotals', { forceServerSync: true })
    }
    if (disconnect) {
      await commit(coreTypes.CART_SET_ITEMS_HASH, null)
      await dispatch('disconnect')
    }
  },
  async cartPull ({
    getters,
    commit,
    dispatch
  }, {
    forceShippingSave = false,
    emptyQuote = false,
    skipShippingCheck = false,
    checkBonuses = false,
    skipPull
  }) {
    const quoteId = emptyQuote ? null : getters.getCartToken

    const { result, resultCode, transparentId } = (!skipPull || !getters.lastPull)
      ? await UserCartService.cartExt(quoteId) : getters.lastPull
    commit(types.CART_SET_LAST_PULL, { result, resultCode })

    if (checkBonuses) {
      await dispatch('checkout/availableBonuses', getters.getCartToken, { root: true })
    }

    const items = (result as CartExtResult)?.items || []

    if (!emptyQuote && (quoteId !== result.quote_id)) {
      dispatch('setCartToken', { token: result.quote_id })
    }

    const resultRemap = items?.map(i => {
      const sqppQty = i.extension_attributes?.sqpp_data?.qty

      const qty = +i?.qty > +sqppQty ? sqppQty : i.qty

      return ({
        'item_id': i.item_id,
        'sku': i.sku,
        'qty': i.qty,
        'qtyCorrection': qty,
        'name': i.name,
        'price': i.price,
        'product_type': i.product_type,
        'quote_id': result.quote_id,
        'extension_attributes': i.extension_attributes
      })
    }) || []

    if (skipShippingCheck) {
      return {
        toShippingSave: null,
        totals: result?.totals,
        result: resultRemap,
        resultCode
      }
    }

    const toShippingSave = await dispatch('shipping-module/syncShipping', {
      addressInformation: result?.address_information,
      clientStateSave: forceShippingSave
    }, { root: true })

    return {
      toShippingSave: toShippingSave?.addressInformation || null,
      totals: result?.totals,
      result: resultRemap,
      resultCode,
      transparentId: transparentId
    }
  },

  cartUpdateItems ({ commit, getters }, { items }) {
    const diffClient = getters.getCartItems.map(i => `${i.sku}:${i.qty}`).sort().join(',')
    const diffSubmitter = items.map(i => `${i.sku}:${i.qty}`).sort().join(',')

    if (diffClient === diffSubmitter) return

    commit(coreTypes.CART_LOAD_CART, items)

    EventBus.$emit('update-changed-cart')
  }
}

export default synchronizeActions
