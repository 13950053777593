import { bonusCardStore } from './store'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'

export const BonusCardModule: StorefrontModule = async function ({ store }, fromCache) {
  if (fromCache?.bonusCard) {
    bonusCardStore.state = fromCache?.bonusCard
  }

  store.registerModule('bonusCard', bonusCardStore)
}
