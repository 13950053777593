import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { attributeModule } from '@vue-storefront/core/modules/catalog/store/attribute'
import { stockModule } from '@vue-storefront/core/modules/catalog/store/stock'
import { taxModule } from '@vue-storefront/core/modules/catalog/store/tax'
import { catalogHooks } from '@vue-storefront/core/modules/catalog/hooks'
import { getAttributesFromMetadata } from '@vue-storefront/core/modules/catalog/helpers/associatedProducts'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import config from 'config'
import { filterChangedProduct, productAfterCustomoptions, productAfterBundleoptions, productAfterPriceupdate, onUserPricesRefreshed } from '@vue-storefront/core/modules/catalog/events'
import { isServer } from '@vue-storefront/core/helpers'
import uniq from 'lodash/uniq'

export const CatalogModule: StorefrontModule = async function ({ store, router, appConfig }, fromCache) {
  StorageManager.init('categories')
  StorageManager.init('attributes')
  StorageManager.init('products')
  StorageManager.init('elasticCache', true, appConfig.server.elasticCacheQuota)

  if (fromCache) {
    attributeModule.state = fromCache?.attribute || attributeModule.state
    stockModule.state = fromCache?.stock || stockModule.state
    taxModule.state = fromCache?.tax || taxModule.state
  }

  store.registerModule('attribute', attributeModule)
  store.registerModule('stock', stockModule)
  store.registerModule('tax', taxModule)

  catalogHooks.afterSetBundleProducts(products => getAttributesFromMetadata(store, products))
  catalogHooks.afterSetGroupedProduct(products => getAttributesFromMetadata(store, products))

  if (!config.entities.attribute.loadByAttributeMetadata) {
    await store.dispatch('attribute/list', { // loading attributes for application use
      filterValues: uniq([...config.products.defaultFilters, ...config.entities.productListWithChildren.includeFields])
    })
  }

  if (!isServer) {
    // Things moved from Product.js
    EventBus.$on('product-after-priceupdate', product => productAfterPriceupdate(product, store))
    EventBus.$on('filter-changed-product', filterOptions => filterChangedProduct(filterOptions, store, router))
    EventBus.$on('product-after-customoptions', payload => productAfterCustomoptions(payload, store))
    EventBus.$on('product-after-bundleoptions', payload => productAfterBundleoptions(payload, store))

    if (config.usePriceTiers || store.getters['tax/getIsUserGroupedTaxActive']) {
      EventBus.$on('user-after-loggedin', onUserPricesRefreshed.bind(null, store, router))
      EventBus.$on('user-after-logout', onUserPricesRefreshed.bind(null, store, router))
    }
  }
}
