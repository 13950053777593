import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { StorageManager } from '@vue-storefront/core/lib/storage-manager';
import { cartStore } from './store';
import { isServer } from '@vue-storefront/core/helpers';
import { cartCacheHandlerFactory } from '@vue-storefront/core/modules/cart/helpers';
import { cartHooks } from '@vue-storefront/core/modules/cart/hooks';
import { syncProductDataWithCart } from 'theme/helpers/product';
import { prepareProductStepValues } from 'theme/helpers';

export const CartModule: StorefrontModule = function ({ store }, fromCache) {
  StorageManager.init('cart')

  if (fromCache?.cart) {
    cartStore.state = fromCache.cart
  }

  store.registerModule('cart', cartStore)

  if (!isServer) {
    store.dispatch('cart/load')
  }

  store.subscribe(cartCacheHandlerFactory())

  cartHooks.beforeRemoveFromCart(({ cartItem }) => syncProductDataWithCart(store.getters['cart/getCartItems'], cartItem));

  cartHooks.afterLoad(cartItems => {
    const productValues = prepareProductStepValues(cartItems);

    if (productValues.length !== 0) {
      store.dispatch('product/loadProductsOptionByValues', { productValues }, { root: true })
    }
  });
}
